//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { removeFromLocalStorage } from "../../helpers/local-storage";
import { updateDebtorData } from "../../components/mixins";
import PpEditorData from "../../components/PpEditorData.vue";
import PpDialog from "../../components/PpDialog.vue";
import PpButton from "../../components/PpButton.vue";
import DebtorDataEmailFormElement from "../../components/DebtorDataFormElements/DebtorDataEmailFormElement.vue";
import DebtorDataNameFormElement from "../../components/DebtorDataFormElements/DebtorDataNameFormElement.vue";
import DebtorDataPersonIdFormElement from "../../components/DebtorDataFormElements/DebtorDataPersonIdFormElement.vue";
import DebtorDataNipFormElement from "../../components/DebtorDataFormElements/DebtorDataNipFormElement.vue";
import DebtorDataPhoneNumberFormElement from "../../components/DebtorDataFormElements/DebtorDataPhoneNumberFormElement.vue";
import DebtorDataAddressFormElement from "../../components/DebtorDataFormElements/DebtorDataAddressFormElement.vue";
import DebtorDataBirthDateFormElement from "../../components/DebtorDataFormElements/DebtorDataBirthDateFormElement.vue";

export default {
    components: {
        PpEditorData,
        PpDialog,
        PpButton,
        DebtorDataEmailFormElement,
        DebtorDataNameFormElement,
        DebtorDataPersonIdFormElement,
        DebtorDataNipFormElement,
        DebtorDataPhoneNumberFormElement,
        DebtorDataAddressFormElement,
        DebtorDataBirthDateFormElement
    },

    props: {
        value: {
            type: [Number, String],
            default: ""
        }
    },
    mixins: [updateDebtorData],

    data() {
        return {
            error_state: false,
            isDisplayConfirmModal: false,

            localdd: {},
            localddv: {},

            loading: false
        };
    },
    computed: {
        debtor_data() {
            return this.$store.state.formData.debtor_personal_data;
        }
    },
    methods: {
        async sendDebtorData() {
            if (this.isDisplayConfirmModal === false) {
                if (this.validate()) {
                    this.isDisplayConfirmModal = true;
                } else {
                    this.$message({
                        type: "error",
                        msg: "Uzupełnij poprawnie swoje dane"
                    });
                    return false;
                }
            } else {
                if (this.loading === true) return;

                this.loading = true;

                try {
                    const O = JSON.parse(JSON.stringify(this.debtor_personal_data));
                    O.name = O.name.trim();
                    O.email = O.email.trim();

                    await this.$axios.$put(
                        `/applications/${this.$store.getters["application/getApplication"]._id}/debtor-personal-data`,
                        O
                    );

                    await this.$axios.$post(
                        `/applications/${this.$store.getters["application/getApplication"]._id}/confirm-debtor-personal-data`
                    );

                    this.$message({
                        type: "success",
                        msg: "Dziękujemy, dane zostały zapisane pomyślnie"
                    });

                    // usunięcie z LS info o tym, że przeszedł onboarding - dzięki temu jeżeli cofniemy wniosek, to ścieżka będzie dokładnie taka sama do przejścia
                    removeFromLocalStorage(
                        `app_${this.$store.getters["application/getApplication"]._id}_onboarding_passed`
                    );

                    this.$router.push({
                        name: "survey"
                    });
                } catch (err) {
                    console.error(err);
                }

                this.loading = false;

                this.closeConfirmDataModal();
                return true;
            }
        },

        closeConfirmDataModal() {
            this.isDisplayConfirmModal = false;
        },

        prevStep() {
            this.$router.push({
                name: "debtor-data-step9"
            });
        },

        validate() {
            let result = true;

            const debtorDataValidations =
                this.$store.state.formData.debtor_personal_data_validations;
            const debtorData = this.$store.state.formData.debtor_personal_data;

            if (debtorDataValidations.name === false) result = false;
            if (debtorDataValidations.email === false) result = false;
            if (debtorDataValidations.birth_date === false) result = false;
            if (debtorData.has_nip === true) {
                if (debtorDataValidations.nip === false) result = false;
            }
            if (debtorDataValidations.pesel === false) result = false;
            if (debtorDataValidations.phone === false) result = false;

            if (debtorDataValidations.address.city === false) result = false;
            if (debtorDataValidations.address.country === false) result = false;
            if (debtorDataValidations.address.house_no === false) result = false;
            if (debtorDataValidations.address.street === false) result = false;
            if (debtorDataValidations.address.zip_code === false) result = false;

            if (debtorData.billing_address_same_as_address !== true) {
                if (debtorDataValidations.billing_address.city === false) result = false;
                if (debtorDataValidations.billing_address.country === false) result = false;
                if (debtorDataValidations.billing_address.house_no === false) result = false;
                if (debtorDataValidations.billing_address.street === false) result = false;
                if (debtorDataValidations.billing_address.zip_code === false) result = false;
            }

            return result;
        },

        displayDate(date) {
            return date.split("-").reverse().join("-");
        }
    },

    async mounted() {
        if (!this.$store.state.initial_layout_set) return;
        await this.$store.dispatch("application/awaitForApplication");

        this.localdd = JSON.parse(JSON.stringify(this.debtor_data));
        this.localddv = JSON.parse(
            JSON.stringify(this.$store.state.formData.debtor_personal_data_validations)
        );

        // to jest po to, aby zwalidować te nieszczęsne komponenty już na wejściu, dzięki czemu jak ktoś odświeży stronę i zaczyta mu dane z API, to nie będzie miał wypełnionych poprawnie pól zaznaczonych na czerwono
        this.$nextTick(_ => {
            this.$refs.fe_sc_1.validate();
            this.$refs.fe_sc_2.validate();
            this.$refs.fe_sc_3.validate();
            this.$refs.fe_sc_4.validate();
            this.$refs.fe_sc_5.validate();
            this.$refs.fe_sc_6.validate();
            this.$refs.fe_sc_7.validate();
            this.$refs.fe_sc_8.validate();
        });
    },

    metaInfo: {
        title: "Zatwierdź swoje dane"
    }
};
