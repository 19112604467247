//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateDebtorData } from "../mixins";

export default {
    mixins: [updateDebtorData],

    methods: {
        validate() {
            return this.$refs.pesel_input.validate();
        },

        resetValidation() {
            return this.$refs.pesel_input.resetValidation();
        },

        validatePersonId(v) {
            let result = true;

            if (!v || v.length == 0) result = "To pole jest wymagane";
            else if (v.length > 11) result = "Numer PESEL powinien zawierać 11 cyfr";
            else if (!this.$store.getters.VALIDATOR_PERSONAL_ID(v)) {
                result = "Proszę podać prawidłowy numer PESEL";
            }

            if (result === true) {
                this.debtor_personal_data_validations.pesel = true;
            } else {
                this.debtor_personal_data_validations.pesel = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                pesel: this.debtor_personal_data_validations.pesel
            });

            return result;
        }
    }
};
