var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('BaseContentWrapper',{scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('PpButton',{attrs:{"color":"primary","text":""},on:{"click":_vm.prevStep}},[_vm._v(" Cofnij ")]),_c('WkTooltip',[_vm._v("popraw dane")]),_c('PpButton',{style:({ height: '64px' }),attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.sendDebtorData}},[_vm._v(" Zatwierdź i przejdź dalej ")])]},proxy:true}])},[_c('StepperContentWrapper',{attrs:{"label":"Twoje dane","value":(10 / 10) * 100,"description":"Krok 10/10"}},[_c('h1',{staticClass:"text-ag mt-0"},[_vm._v("Sprawdź swoje dane")]),[_c('div',{staticClass:"udfe"},[_c('PpEditorData',{ref:"edit_name",attrs:{"label":"Imię i nazwisko","title":"Edytuj imię i nazwisko","err":!_vm.$store.state.formData.debtor_personal_data_validations.name,"err-msg":"Nieprawidłowe imię"},on:{"submit":function () {
                                if (_vm.$refs.fe_name.validate()) {
                                    _vm.$refs.edit_name.closeModal();
                                }
                            },"cancel":function () {
                                _vm.emitUpdate(Object.assign({}, _vm.$store.state.formData.debtor_personal_data,
                                    {name: _vm.localdd.name}));
                                _vm.$store.commit('formData/setDebtorDataValidation', Object.assign({}, _vm.$store.state.formData.debtor_personal_data_validations,
                                    {name: _vm.localddv.name}));
                            }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text"},[_vm._v(" "+_vm._s(_vm.debtor_data.name)+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('DebtorDataNameFormElement',{ref:"fe_name",on:{"submit":function () {
                                        if (_vm.$refs.fe_name.validate()) {
                                            _vm.$refs.edit_name.closeModal();
                                        }
                                    }}})]},proxy:true}])}),_c('PpEditorData',{ref:"edit_email",attrs:{"label":"Adres e-mail","title":"Edytuj adres e-mail","err":!_vm.$store.state.formData.debtor_personal_data_validations.email,"err-msg":"Nieprawidłowy e-mail"},on:{"submit":function () {
                                if (_vm.$refs.fe_email.validate()) {
                                    _vm.$refs.edit_email.closeModal();
                                }
                            },"cancel":function () {
                                _vm.emitUpdate(Object.assign({}, _vm.$store.state.formData.debtor_personal_data,
                                    {email: _vm.localdd.email}));
                                _vm.$store.commit('formData/setDebtorDataValidation', Object.assign({}, _vm.$store.state.formData.debtor_personal_data_validations,
                                    {email: _vm.localddv.email}));
                            }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text"},[_vm._v(" "+_vm._s(_vm.debtor_data.email)+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('DebtorDataEmailFormElement',{ref:"fe_email",on:{"submit":function () {
                                        if (_vm.$refs.fe_email.validate()) {
                                            _vm.$refs.edit_email.closeModal();
                                        }
                                    }}})]},proxy:true}])}),_c('PpEditorData',{ref:"edit_pesel",attrs:{"label":"PESEL","title":"Edytuj PESEL","err":!_vm.$store.state.formData.debtor_personal_data_validations.pesel,"err-msg":"Nieprawidłowy PESEL"},on:{"submit":function () {
                                if (_vm.$refs.fe_pesel.validate()) {
                                    _vm.$refs.edit_pesel.closeModal();
                                }
                            },"cancel":function () {
                                _vm.emitUpdate(Object.assign({}, _vm.$store.state.formData.debtor_personal_data,
                                    {pesel: _vm.localdd.pesel}));
                                _vm.$store.commit('formData/setDebtorDataValidation', Object.assign({}, _vm.$store.state.formData.debtor_personal_data_validations,
                                    {pesel: _vm.localddv.pesel}));
                            }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text"},[_vm._v(" "+_vm._s(_vm.debtor_data.pesel)+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('DebtorDataPersonIdFormElement',{ref:"fe_pesel",on:{"submit":function () {
                                        if (_vm.$refs.fe_pesel.validate()) {
                                            _vm.$refs.edit_pesel.closeModal();
                                        }
                                    }}})]},proxy:true}])}),_c('PpEditorData',{ref:"edit_nip",attrs:{"label":"NIP","title":"Czy posiadasz numer NIP?","err":!_vm.$store.state.formData.debtor_personal_data_validations.nip &&
                            _vm.debtor_data.has_nip,"err-msg":"Nieprawidłowy NIP"},on:{"submit":function () {
                                if (_vm.$refs.fe_nip.validate()) {
                                    _vm.$refs.edit_nip.closeModal();
                                }
                            },"cancel":function () {
                                _vm.emitUpdate(Object.assign({}, _vm.$store.state.formData.debtor_personal_data,
                                    {nip: _vm.localdd.nip}));
                                _vm.$store.commit('formData/setDebtorDataValidation', Object.assign({}, _vm.$store.state.formData.debtor_personal_data_validations,
                                    {nip: _vm.localddv.nip}));
                            }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text"},[_vm._v(" "+_vm._s(_vm.debtor_data.has_nip ? _vm.debtor_data.nip : "Nie posiadam NIP-u")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('DebtorDataNipFormElement',{ref:"fe_nip",attrs:{"single-column":""},on:{"submit":function () {
                                        if (_vm.$refs.fe_nip.validate()) {
                                            _vm.$refs.edit_nip.closeModal();
                                        }
                                    }}})]},proxy:true}])}),_c('PpEditorData',{ref:"edit_phone",attrs:{"label":"Telefon","title":"Edytuj telefon","err":!_vm.$store.state.formData.debtor_personal_data_validations.phone,"err-msg":"Nieprawidłowy telefon"},on:{"submit":function () {
                                if (_vm.$refs.fe_phone.validate()) {
                                    _vm.$refs.edit_phone.closeModal();
                                }
                            },"cancel":function () {
                                _vm.emitUpdate(Object.assign({}, _vm.$store.state.formData.debtor_personal_data,
                                    {phone: _vm.localdd.phone}));
                                _vm.$store.commit('formData/setDebtorDataValidation', Object.assign({}, _vm.$store.state.formData.debtor_personal_data_validations,
                                    {phone: _vm.localddv.phone}));
                            }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text"},[_vm._v(" "+_vm._s(_vm.debtor_data.phone)+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('DebtorDataPhoneNumberFormElement',{ref:"fe_phone",on:{"submit":function () {
                                        if (_vm.$refs.fe_phone.validate()) {
                                            _vm.$refs.edit_phone.closeModal();
                                        }
                                    }}})]},proxy:true}])}),_c('PpEditorData',{ref:"edit_birth_date",attrs:{"label":"Data urodzenia","title":"Edytuj datę urodzenia","err":!_vm.$store.state.formData.debtor_personal_data_validations.birth_date,"err-msg":"Nieprawidłowa data"},on:{"submit":function () {
                                if (_vm.$refs.fe_birth_date.validate()) {
                                    _vm.$refs.edit_birth_date.closeModal();
                                }
                            },"cancel":function () {
                                _vm.emitUpdate(Object.assign({}, _vm.$store.state.formData.debtor_personal_data,
                                    {birth_date: _vm.localdd.birth_date}));
                                _vm.$store.commit('formData/setDebtorDataValidation', Object.assign({}, _vm.$store.state.formData.debtor_personal_data_validations,
                                    {birth_date: _vm.localddv.birth_date}));
                            }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',{staticClass:"editor-data--overflow-text"},[_vm._v(" "+_vm._s(_vm.$store.state.formData.debtor_personal_data_validations .birth_date ? _vm.displayDate(_vm.debtor_data.birth_date) : "-")+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('DebtorDataBirthDateFormElement',{ref:"fe_birth_date",on:{"submit":function () {
                                        if (_vm.$refs.fe_birth_date.validate()) {
                                            _vm.$refs.edit_birth_date.closeModal();
                                        }
                                    }}})]},proxy:true}])}),_c('PpEditorData',{ref:"edit_address",attrs:{"label":"Adres zamieszkania","title":"Edytuj adres zamieszkania","err":Object.values(
                                _vm.$store.state.formData.debtor_personal_data_validations.address
                            ).includes(false),"err-msg":"Nieprawidłowy adres"},on:{"submit":function () {
                                if (_vm.$refs.fe_address.validate()) {
                                    _vm.$refs.edit_address.closeModal();
                                }
                            },"cancel":function () {
                                _vm.emitUpdate(Object.assign({}, _vm.$store.state.formData.debtor_personal_data,
                                    {address: _vm.localdd.address}));
                                _vm.$store.commit('formData/setDebtorDataValidation', Object.assign({}, _vm.$store.state.formData.debtor_personal_data_validations,
                                    {address: _vm.localddv.address}));
                            }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters["displayGeneralAddress"](_vm.debtor_data.address))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('DebtorDataAddressFormElement',{ref:"fe_address",attrs:{"address-type":"address"},on:{"submit":function () {
                                        if (_vm.$refs.fe_address.validate()) {
                                            _vm.$refs.edit_address.closeModal();
                                        }
                                    }}})]},proxy:true}])}),_c('PpEditorData',{ref:"edit_billing_address",attrs:{"label":"Adres do korespondencji","title":"Ten sam adres do korespondencji","err":Object.values(
                                _vm.$store.state.formData.debtor_personal_data_validations
                                    .billing_address
                            ).includes(false) && !_vm.debtor_data.billing_address_same_as_address,"err-msg":"Nieprawidłowy adres"},on:{"submit":function () {
                                if (_vm.$refs.fe_billing_address.validate()) {
                                    _vm.$refs.edit_billing_address.closeModal();
                                }
                            },"cancel":function () {
                                _vm.emitUpdate(Object.assign({}, _vm.$store.state.formData.debtor_personal_data,
                                    {billing_address: _vm.localdd.billing_address}));
                                _vm.$store.commit('formData/setDebtorDataValidation', Object.assign({}, _vm.$store.state.formData.debtor_personal_data_validations,
                                    {billing_address: _vm.localddv.billing_address}));
                            }},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.debtor_data.billing_address_same_as_address ? "Taki sam jak adres zamieszkania" : _vm.$store.getters["displayGeneralAddress"]( _vm.debtor_data.billing_address ))+" ")])]},proxy:true},{key:"elements",fn:function(){return [_c('DebtorDataAddressFormElement',{ref:"fe_billing_address",attrs:{"single-column":"","address-type":"billing_address"}})]},proxy:true}])})],1)],_c('PpDialog',{attrs:{"show":_vm.isDisplayConfirmModal,"cancelable":false,"max-width":"450"},on:{"close":_vm.closeConfirmDataModal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s("Zatwierdzenie danych"))]},proxy:true}])},[_vm._v(" Upewnij się, że wprowadzone dane są poprawne. "),_c('b',[_vm._v("Uwaga!")]),_vm._v(" Po zatwierdzeniu utracisz możliwość ich edycji. "),_c('PpDialogFooter',{attrs:{"slot":"footer"},slot:"footer"},[_c('PpButton',{attrs:{"text":""},on:{"click":_vm.closeConfirmDataModal}},[_vm._v("Anuluj")]),_c('PpButton',{attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.sendDebtorData}},[_vm._v("Wyślij")])],1)],1),_c('div',{staticStyle:{"display":"none"}},[_c('DebtorDataNameFormElement',{ref:"fe_sc_1"}),_c('DebtorDataEmailFormElement',{ref:"fe_sc_2"}),_c('DebtorDataPersonIdFormElement',{ref:"fe_sc_3"}),_c('DebtorDataNipFormElement',{ref:"fe_sc_4",attrs:{"single-column":""}}),_c('DebtorDataPhoneNumberFormElement',{ref:"fe_sc_5"}),_c('DebtorDataBirthDateFormElement',{ref:"fe_sc_6"}),_c('DebtorDataAddressFormElement',{ref:"fe_sc_7",attrs:{"address-type":"address"}}),_c('DebtorDataAddressFormElement',{ref:"fe_sc_8",attrs:{"single-column":"","address-type":"billing_address"}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }