//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateDebtorData } from "../mixins";

export default {
    mixins: [updateDebtorData],

    methods: {
        validate() {
            return this.$refs.name_input.validate();
        },

        resetValidation() {
            return this.$refs.name_input.resetValidation();
        },

        validateName(v) {
            let result = true;
            const pv = v.trim();

            if (!pv || pv.length == 0) result = "To pole jest wymagane";
            else if (pv.length > 127)
                result = "Maksymalna długość imienia i nazwiska to 127 znaków";
            else if (!/^[^ ]+ +[^ ]+$/.test(pv))
                result = "Proszę wpisać imię i nazwisko rozdzielone spacją";

            if (result === true) {
                this.debtor_personal_data_validations.name = true;
            } else {
                this.debtor_personal_data_validations.name = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                name: this.debtor_personal_data_validations.name
            });

            return result;
        }
    }
};
