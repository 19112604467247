//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DebtorDataPhoneNumberFormElement from "@/components/DebtorDataFormElements/DebtorDataPhoneNumberFormElement";

export default {
    components: {
        DebtorDataPhoneNumberFormElement
    },

    data() {
        return {
            loading: false
        };
    },

    methods: {
        async nextStep() {
            if (!this.$refs.fe.validate()) return;
            if (this.loading === true) return;

            this.loading = true;

            try {
                await this.$axios.$put(
                    `/applications/${this.$store.getters["application/getApplication"]._id}/debtor-personal-data`,
                    {
                        phone: this.$refs.fe.getValue().phone
                    }
                );

                this.$router.push({
                    name: "debtor-data-step6"
                });
            } catch (err) {
                console.error(err);
            }

            this.loading = false;
        },

        prevStep() {
            this.$router.push({
                name: "debtor-data-step4"
            });
        }
    },

    metaInfo: {
        title: "Numer telefonu - Twoje dane"
    }
};
