//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateDebtorData } from "../mixins";

export default {
    mixins: [updateDebtorData],

    methods: {
        validate() {
            return this.$refs.email_input.validate();
        },

        resetValidation() {
            return this.$refs.email_input.resetValidation();
        },

        validateEmail(v) {
            let result = true;
            const pv = v.trim();

            if (!pv || pv.length == 0) result = "To pole jest wymagane";
            else if (pv.length > 63) result = "Maksymalna długość maila to 63 znaki";
            else if (!this.$store.state.REGEX_EMAIL.test(pv))
                result = "Proszę podać prawidłowy e-mail";

            if (result === true) {
                this.debtor_personal_data_validations.email = true;
            } else {
                this.debtor_personal_data_validations.email = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                email: this.debtor_personal_data_validations.email
            });

            return result;
        }
    }
};
