//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateDebtorData } from "../mixins";
import PpRadioButton from "../../components/PpRadioButton.vue";
import countries from "../../static/countries.json";

export default {
    mixins: [updateDebtorData],

    props: {
        addressType: {
            type: String
        }
    },

    components: {
        PpRadioButton
    },

    data() {
        return {
            country_select_search: ""
        };
    },

    computed: {
        countrySelectItems() {
            const c = countries
                .map(country => ({
                    text: country.name.pl,
                    value: country.alpha2
                }))
                .sort((a, b) => {
                    if (a.text > b.text) return 1;
                    else if (b.text > a.text) return -1;
                    else return 0;
                });
            if (this.country_select_search != "") {
                return c.filter(country =>
                    country.text.toLowerCase().includes(this.country_select_search.toLowerCase())
                );
            }
            return c;
        }
    },

    methods: {
        validateCountry(v) {
            let result = true;

            this.$refs.zip_code_input.validate();
            if (!v || v.length == 0) result = "To pole jest wymagane";

            if (result === true) {
                this.debtor_personal_data_validations[this.addressType].country = true;
            } else {
                this.debtor_personal_data_validations[this.addressType].country = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                [this.addressType]: {
                    ...this.$store.state.formData.debtor_personal_data_validations[
                        this.addressType
                    ],
                    country: this.debtor_personal_data_validations[this.addressType].country
                }
            });

            return result;
        },

        validateCity(v) {
            let result = true;

            if (!v || v.length == 0) result = "To pole jest wymagane";
            else if (v.length > 127) result = "Maksymalna długość miasta to 127 znaków";

            if (result === true) {
                this.debtor_personal_data_validations[this.addressType].city = true;
            } else {
                this.debtor_personal_data_validations[this.addressType].city = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                [this.addressType]: {
                    ...this.$store.state.formData.debtor_personal_data_validations[
                        this.addressType
                    ],
                    city: this.debtor_personal_data_validations[this.addressType].city
                }
            });

            return result;
        },

        validateZipCode(v) {
            let result = true;

            if (!v || v.length == 0) result = "To pole jest wymagane";
            else if (this.debtor_personal_data[this.addressType].country === "PL") {
                if (!/^[0-9]{2}-[0-9]{3}$/.test(v))
                    result = "Proszę podać prawidłowy kod pocztowy (00-000)";
            } else if (!/^[A-Za-z0-9_-\s\/]{3,15}$/.test(v))
                result = "Podano nieprawidłowy kod pocztowy";

            if (result === true) {
                this.debtor_personal_data_validations[this.addressType].zip_code = true;
            } else {
                this.debtor_personal_data_validations[this.addressType].zip_code = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                [this.addressType]: {
                    ...this.$store.state.formData.debtor_personal_data_validations[
                        this.addressType
                    ],
                    zip_code: this.debtor_personal_data_validations[this.addressType].zip_code
                }
            });

            return result;
        },

        validateApartmentNumber(v) {
            let result = true;

            if ((v && v.length < 1) || v.length > 7)
                result = "Numer lokalu musi posiadać 1-7 znaków";
            if (result === true) {
                this.debtor_personal_data_validations[this.addressType].apartment_no = true;
            } else {
                this.debtor_personal_data_validations[this.addressType].apartment_no = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                [this.addressType]: {
                    ...this.$store.state.formData.debtor_personal_data_validations[
                        this.addressType
                    ],
                    apartment_no:
                        this.debtor_personal_data_validations[this.addressType].apartment_no
                }
            });

            return result;
        },

        validateHouseNumber(v) {
            let result = true;
            if (v === undefined || v === "") result = "To pole jest wymagane";
            else if (v.length < 1 || v.length > 7)
                result = "Numer budynku musi posiadać 1-7 znaków";
            else if (v < 1) result = "Numer powinien być większy od 0";

            if (result === true) {
                this.debtor_personal_data_validations[this.addressType].house_no = true;
            } else {
                this.debtor_personal_data_validations[this.addressType].house_no = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                [this.addressType]: {
                    ...this.$store.state.formData.debtor_personal_data_validations[
                        this.addressType
                    ],
                    house_no: this.debtor_personal_data_validations[this.addressType].house_no
                }
            });

            return result;
        },

        validateStreet(v) {
            let result = true;

            if (!v || v.length == 0) result = "To pole jest wymagane";
            else if (v.length > 63) result = "Maksymalna długość ulicy to 63 znaki";

            if (result === true) {
                this.debtor_personal_data_validations[this.addressType].street = true;
            } else {
                this.debtor_personal_data_validations[this.addressType].street = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                [this.addressType]: {
                    ...this.$store.state.formData.debtor_personal_data_validations[
                        this.addressType
                    ],
                    street: this.debtor_personal_data_validations[this.addressType].street
                }
            });

            return result;
        },

        validate() {
            if (
                this.debtor_personal_data.billing_address_same_as_address === false ||
                this.addressType !== "billing_address"
            ) {
                const validation_inputs = [
                    this.$refs.street_input.validate(),
                    this.$refs.house_no_input.validate(),
                    this.$refs.apartment_no_input.validate(),
                    this.$refs.zip_code_input.validate(),
                    this.$refs.city_input.validate(),
                    this.$refs.country_select.validate()
                ];

                if (validation_inputs.indexOf(false) !== -1) {
                    return false;
                }
            } else {
                this.debtor_personal_data[this.addressType] = {
                    ...this.debtor_personal_data[this.addressType]
                };
                this.emitUpdate({
                    ...this.$store.state.formData.debtor_personal_data,
                    [this.addressType]: {
                        ...this.$store.state.formData.debtor_personal_data[this.addressType],
                        ...this.debtor_personal_data[this.addressType]
                    }
                });
            }
            return true;
        },

        resetValidation() {
            return this.$refs.address_input.resetValidation();
        }
    },

    async created() {
        if (!this.$store.state.initial_layout_set) return;
        await this.$store.dispatch("application/awaitForApplication");

        if (this.debtor_personal_data[this.addressType].country == "") {
            this.debtor_personal_data[this.addressType].country = "PL";
            this.emitUpdate({
                ...this.$store.state.formData.debtor_personal_data,
                [this.addressType]: {
                    ...this.$store.state.formData.debtor_personal_data[this.addressType],
                    country: this.debtor_personal_data[this.addressType].country
                }
            });
        }
    }
};
