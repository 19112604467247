//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DebtorDataAddressFormElement from "@/components/DebtorDataFormElements/DebtorDataAddressFormElement";

export default {
    components: {
        DebtorDataAddressFormElement
    },

    data() {
        return {
            loading: false
        };
    },

    methods: {
        async nextStep() {
            if (!this.$refs.fe.validate()) return;
            if (this.loading === true) return;

            this.loading = true;

            try {
                await this.$axios.$put(
                    `/applications/${this.$store.getters["application/getApplication"]._id}/debtor-personal-data`,
                    {
                        address: this.$refs.fe.getValue().address
                    }
                );
                this.$router.push({
                    name: "debtor-data-step8"
                });
            } catch (err) {
                console.error(err);
            }

            this.loading = false;
        },

        prevStep() {
            this.$router.push({
                name: "debtor-data-step6"
            });
        }
    },

    metaInfo: {
        title: "Adres zamieszkania - Twoje dane"
    }
};
