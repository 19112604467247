//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    methods: {
        nextStep() {
            this.$router.push({
                name: "debtor-data-step10"
            });
        },

        prevStep() {
            this.$router.push({
                name: "debtor-data-step8"
            });
        }
    },

    metaInfo: {
        title: "Dane zostały wprowadzone - Twoje dane"
    }
};
