//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpComboDateInput from "../PpComboDateInput.vue";
import { updateDebtorData } from "../mixins";

export default {
    mixins: [updateDebtorData],

    components: {
        PpComboDateInput
    },

    methods: {
        validate() {
            return this.$refs.birthDate_input.validate();
        },

        resetValidation() {
            return this.$refs.birthDate_input.resetValidation();
        },

        checkAgeOfConsent(date) {
            let result = true;

            const date_subtract_years = new Date();

            date_subtract_years.setFullYear(date_subtract_years.getFullYear() - 18);

            const a = new Date() - new Date(date);
            const b = new Date() - new Date(date_subtract_years);

            if (!(a > b)) result = "Jesteś niepełnoletni";

            if (result === true) {
                this.debtor_personal_data_validations.birth_date = true;
            } else {
                this.debtor_personal_data_validations.birth_date = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                birth_date: this.debtor_personal_data_validations.birth_date
            });

            return result;
        },

        checkCorrectnessOfDate(v) {
            if (v !== false) {
                this.debtor_personal_data_validations.birth_date = true;
            } else {
                this.debtor_personal_data_validations.birth_date = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                birth_date: this.debtor_personal_data_validations.birth_date
            });

            return true;
        }
    }
};
