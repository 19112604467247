//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateDebtorData } from "../mixins";

export default {
    mixins: [updateDebtorData],

    methods: {
        validate() {
            return this.$refs.phone_input.validate();
        },

        resetValidation() {
            return this.$refs.phone_input.resetValidation();
        },

        validatePhoneNumber(num) {
            let valid = true;

            if (num === undefined || num === "") valid = "To pole jest wymagane";
            else if (num.length < 9 || num.length > 15)
                valid = "Numer telefonu musi posiadać 9-15 znaków";
            else if (!/^\+?[0-9 -]{9,14}$/.test(num))
                valid =
                    "Numer telefonu może składać się maksymalnie z 14 cyfr poprzedzonych opcjonalnym znakiem '+'";
            else if (num.replace(/[^0-9]/g, "").length < 9)
                valid = "Numer telefonu musi składać się z przynajmniej 9 cyfr";

            if (valid === true) {
                this.debtor_personal_data_validations.phone = true;
            } else {
                this.debtor_personal_data_validations.phone = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                phone: this.debtor_personal_data_validations.phone
            });

            return valid;
        }
    }
};
