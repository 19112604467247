//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { updateDebtorData } from "../mixins";
import PpRadioButton from "../../components/PpRadioButton.vue";

export default {
    mixins: [updateDebtorData],

    components: {
        PpRadioButton
    },

    methods: {
        validate() {
            if (this.$refs.nip_input) {
                return this.$refs.nip_input.validate();
            }
            return true;
        },

        resetValidation() {
            return this.$refs.nip_input.resetValidation();
        },

        validateTaxNumberPl(v) {
            let result = true;

            if (!v || v.length == 0) result = "To pole jest wymagane";
            else if (!this.$store.getters.VALIDATOR_TAX_NUMBER_PL(v)) {
                result = "Proszę podać prawidłowy numer NIP";
            }

            if (result === true) {
                this.debtor_personal_data_validations.nip = true;
            } else {
                this.debtor_personal_data_validations.nip = false;
            }

            this.emitValidationUpdate({
                ...this.$store.state.formData.debtor_personal_data_validations,
                nip: this.debtor_personal_data_validations.nip
            });

            return result;
        }
    }
};
